exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie_policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy_policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-services-data-services-index-jsx": () => import("./../../../src/pages/services/data-services/index.jsx" /* webpackChunkName: "component---src-pages-services-data-services-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-media-buying-index-jsx": () => import("./../../../src/pages/services/media-buying/index.jsx" /* webpackChunkName: "component---src-pages-services-media-buying-index-jsx" */),
  "component---src-pages-services-staff-augmentation-index-jsx": () => import("./../../../src/pages/services/staff-augmentation/index.jsx" /* webpackChunkName: "component---src-pages-services-staff-augmentation-index-jsx" */),
  "component---src-pages-thanks-draft-thanks-jsx": () => import("./../../../src/pages/thanks/draft_thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-draft-thanks-jsx" */),
  "component---src-pages-thanks-thanks-jsx": () => import("./../../../src/pages/thanks/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-thanks-jsx" */)
}

